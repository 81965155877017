* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.custom-nav-links {
    color: #fff;
    text-decoration: none;
    font-weight: 400;
    padding: 10px;
    font-size: 12px;
    font-weight: 500;
}

.adminNavbar {
    background-color: #0077ff;
}

.actives {
    border-bottom: 2px solid #fff;
    color: #fff !important;
    padding: 10px;
}

nav .nav-link {
    color: #fff;
    text-decoration: none;
    font-weight: 400;
    font-size: 30px;
    font-weight: 500;
    padding: 0;
    padding-right: 10px !important;
}

.dropdown-toggle::after{
    display: none;
}

.navDropdowns{
    padding-left: 0 !important;
}

.dropdown-item {
    font-size: 13px;
}

.emailSuccess{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 70vh;
    text-align: center;
}

.rowName{
    text-decoration: none;
    color: #000;
}

.rowName:hover{
    cursor: pointer;
    color: #0077ff;
}

/* .form-check-input:focus{
    background-color: red;
} */

.emailHeader{
    width: 70% !important;
}

.emailHeader .offcanvas-header{
    padding: 0px;
    padding-left: 15px;
    background-color: #011f41;
    color: #fff;
    border-bottom: 1px solid #424242;
}

.cameraIcon{
    background: rgb(46, 46, 46);
    font-size: 25px;
    padding: 5px;
    color: #dddddd;
    border-radius: 50%;
    position: relative;
    margin-top: 40px;
    margin-left: -15px;
}

#productActive {
    margin-left: 91px;
}
@media (max-width: 574px) {
    #productActive {
        margin-left: 60px;
    }
}
@media (max-width: 300px) {
    #productActive {
        margin-left: 0px;
    }
}