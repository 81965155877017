.volunteerBtn {
  border-radius: 100px;
  background: #ff3e54;
  color: #ff3e54;
  padding: 12px 23px;
  font-family: "Eudoxus Sans", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 5px;
  background-color: transparent;
  border: 2px solid #ff3e54;
}

.volunteerBtn:hover {
  background: #ff001e;
  color: #fff;
}

.donateBtn {
  border-radius: 100px;
  background-color: #3d63ea;
  color: #fff;
  padding: 12px 25px;
  font-family: "Eudoxus Sans", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  margin-right: 10px;
  margin-bottom: 5px;
  line-height: normal;
  border: 2px solid #3d63ea;
}

.donateBtn:hover {
  background-color: transparent;
  color: #3d63ea;
  border: 2px solid #3d63ea;
}

.nav-link {
  color: #000; /* default color */
  transition: color 0.3s ease; /* smooth transition effect */
}

.nav-link:hover {
  color: #007bff; /* primary color on hover */
}
.key-features:hover {
  transform: scale(1.05);
  cursor: pointer;
}

.navBar-CRM {
  position: sticky;
  top: 0;
  background-color: rgb(255, 255, 255, 0.85);
  background-color: rgba(255, 255, 255, 0.82);
  backdrop-filter: blur(6px);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
  z-index: 999;
}

.register-image {
  position: sticky;
  top: 25%;
}
.headers {
  position: relative;
  text-decoration: none;
  font-weight: 550;
  color: rgba(0, 0, 0, 0.85);
}

.headers::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 0;
  height: 2px;
  background-color: #0080ff;
  transition: width 0.1s;
  margin: 0 6px;
}

.headers:hover::after {
  width: 78%;
  margin: 0 6px;
  background-color: #0080ff;
}
.headers:hover {
  color: #0080ff;
}

.headers.active::after {
  width: 85%;
  margin-bottom: 10px;
}

.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 37px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.my-float {
  margin-top: 16px;
}
.Calenderview {
  background-color: #007bff; 
  color: #ffffff; 
  font-family: Arial, sans-serif; 
  font-weight: bold; 
  height: 3rem;
}
.calenderEdit-icon{
  position: absolute;
  right: 60px;
  top: 4px;

 }

 /* .container {
  text-align: center;
  padding: 20px;
} */

.title {
  font-size: 2em;
  margin-bottom: 20px;
}

.highlight {
  color: #7a5cff;
}

.agenda{
  text-decoration: none;
  list-style: none;
}

.heading-register {
  font-family: 'Arial', sans-serif;
  font-weight: 700;
  font-size: 32px;
}

.heading-register .register {
  color: #000;
}

.heading-register .community {
  color: #3d63ea;
  margin-left: 5px;
}
.large-number {
  font-size: 4rem; 
  font-weight: 600;
}
.quote-container {
  /* background-color: #2E3271;  */
  color: rgb(7, 7, 7); 
  /* padding: 50px; */
  text-align: center;
  font-family: 'Arial', sans-serif; 
  border-radius: 8px;
  width: 350px; 
  margin: 0 auto; 
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);  */
}

.quote-text {
  font-size: 2.3rem; /* Increased text size */
  font-weight: bold;
  line-height: 1.3;
  margin: 0;
  letter-spacing: 0.5px; /* Slight spacing between letters */
}
.time-in {
  display: flex;
  justify-content: center; /* Horizontally centers the content */
  align-items: center; /* Vertically centers the content if the span has height */
  text-align: center; /* Ensures the text is centered */
  width: 100%; /* Takes up the full width of its container */
}
