@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

/* TopBar  */
.topbar {
  top: 0;
  position: fixed;
  width: 100%;
  z-index: 999;
  background-color: #3d63ea;
  color: #fff;
  padding: 5px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mobileSection {
  display: flex;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  align-items: center;
}

.socialIcons {
  display: flex;
  align-items: center;
}

.icon {
  margin-left: 10px;
  font-size: 20px;
  cursor: pointer;
}

/* Header  */

.active {
  border-bottom: 2px solid #ffffff;
  padding: 10px;
}

.header {
  top: 33px;
  position: fixed;
  z-index: 1000;
  width: 100%;
  background: #fff !important;
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.2);
}

.custom-nav-link {
  color: rgba(0, 0, 0, 0.85);
  font-weight: bold;
  padding: 10px;
}

.navbar-brand {
  padding: 0px;
}

nav .nav-link {
  color: rgba(0, 0, 0, 0.85);
  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  /* padding-right: 20px !important; */
  font-weight: 600;
  line-height: normal;
}

nav .nav-link Link {
  color: rgba(0, 0, 0, 0.85) !important;
}

/* Footer  */
.footerSection {
  background: #f1f6fe;
  color: rgba(0, 0, 0, 0.8);
}

.footerText {
  color: rgba(0, 0, 0, 0.8);
  font-family: "Nunito Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 200;
  line-height: 130%;
}

.footerIcon {
  border-radius: 50%;
  padding: 10px;
  font-size: 40px;
  margin: 0px 10px;
  background: rgba(255, 255, 255, 0.2);
}

.footerRow {
  padding: 48px 49px;
  margin: 5px;
  background: rgba(255, 255, 255, 0.1);
}

.footerHeading {
  font-feature-settings: "clig" off, "liga" off;

  /* Subtitle 1_18 */
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  /* 144.444% */
}

.footerContent {
  margin-bottom: 15px;
  font-family: "Nunito Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.footerCopyrights {
  color: #e1e1e1;
  font-family: "Nunito Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 300 !important;
  line-height: 130%;
}

/* Contact Us  */

.contactCard {
  border-radius: 10px;
  padding: 10px;
  background: #fff;
  box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.1);
}

.contactColumn {
  background-color: #3d63eacc;
  color: #fff;
  background-image: url("../assets/contact_background.png");
  background-repeat: no-repeat;
  background-position: bottom right;
}

.contactInformation {
  font-family: "Poppins";
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0px;
}

.contactContent {
  color: #c9c9c9;
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.contactText {
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.contactIcon {
  border-radius: 50%;
  padding: 10px;
  font-size: 40px;
  margin: 0px 10px;
  background: rgba(255, 0, 0, 0.8);
}

.contactIcon:hover {
  background: rgba(255, 255, 255, 0.2);
}

.contactFields {
  color: #000;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 166.667% */
}

.contactInput {
  border: none;
  border-bottom: 2px solid #959595;
  border-radius: 0px;
}

.contactButton {
  border-radius: 5px;
  background: #ff3e54;
  width: 200px;
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.12);
  padding: 10px 38px;
}

/* Hero Section */

.Hero {
  background-image: url("../assets/heroBackground.png");
  background-repeat: no-repeat;
  background-size: auto;
}

.heroHeading {
  color: #0e1f51;
  font-family: "Raleway";
  font-size: 43px;
  font-style: normal;
  font-weight: bolder;
  line-height: 58px;
}

.heroCRM {
  color: #ff3e54;
  font-family: "Raleway";
  font-size: 43px;
  font-style: normal;
  font-weight: 700;
  line-height: 58px;
}

.heroSubHeading {
  color: #41424c;
  font-family: "Raleway";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 2.4px;
}

.heroBtn {
  border-radius: 5px;
  background: #3d63ea;
}

.heroImageBackground {
  background-image: url("../assets/heroImageBackground.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}

/* SignIn  */

.signIn .registerWord {
  color: #3d63ea;
  font-family: "Nunito Sans", sans-serif;
}

.signIn .contactsubmitBtn {
  width: 100%;
  background-color: #3d63ea;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.signIn .contactsubmitBtn:hover {
  background-color: #1a44da;
  border: none;
}

.signIn .forgotWord {
  font-family: "Nunito Sans", sans-serif;
  font-size: 15px;
  font-weight: 500;
}

/* .signIn .password-link {
    color: blue;
    text-decoration: none;
} */

.signIn .form-control {
  /* width: calc(100% - 40px);  */
  border-radius: 5px;
}

.signIn #inputStreet {
  width: 100%;
}

.signIn #inputCity {
  width: 100%;
}

.signIn #inputState {
  width: "260px";
}

.signIn #inputZip {
  width: "260px";
}

@media (max-width: 991px) {
  .signIn #inputState {
    width: 100%;
  }

  .signIn #inputZip {
    width: 100%;
  }
}

/* @media (max-width: 991px) {
    .signIn .form-control {
        width: 100%;
    }
} */

.signIn #inputCountry {
  width: 100%;
}

.signIn .phoneSelect {
  width: 5px;
  padding: 5px;
  font-size: 14px;
}

.signIn .eye-icon {
  border: none;
  color: #959595;
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.signIn .input-group {
  position: relative;
}

.signIn .input-group-text.eye-icon {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  /* transform: translateX(150%); */
  /* background-color: transparent; */
  /* border: none; */
  padding: 0;
  z-index: 3;
}

.signIn #company_password,
.signIn #confirm_password {
  width: calc(100% - 40px);
  /* Adjust the width as needed */
}

.signIn #company_password {
  border-radius: 5px;
}

.signIn #confirm_password {
  border-radius: 5px;
}

.signIn .input-group .form-control {
  z-index: 0;
}

.signIn .form-group {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}

/* Login Page  */

.logIn .registerWord {
  color: #3d63ea;
  font-family: "Nunito Sans", sans-serif;
}

.logIn .contactsubmitBtn {
  width: 100%;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.logIn .contactsubmitBtn:hover {
  width: 100%;
  border: none;
}

/* Create Leads  */

.createLead .form-size {
  width: 60%;
}

/* Edit Leads  */

.editLead .form-size {
  width: 60%;
}

/* Create Contact  */

.createContacts .form-size {
  width: 60%;
}

/* Appointment  */

.bookAppointment button.btn-close {
  bs-btn-close-color: #fff;
}

.intl-tel-input,
.iti {
  width: 100%;
}

@media (max-width: 500px) {

  .sm-device,
  .form-size {
    display: block !important;
    width: 100% !important;
  }
}

/* Dragable sales work */
.row-container>* {
  padding-left: 0px;
  padding-right: 0px;
}

.dragable-heading-text {
  font-family: "Source Sans 3", sans-serif;
  margin-bottom: 0px;
  font-size: smaller;
  font-weight: 700;
}

.dragable-content-text {
  font-family: "Source Sans 3", sans-serif;
  margin-bottom: 0px;
  font-size: medium;
  font-weight: 650;
}

.dragable-heading-count {
  display: flex;
  font-weight: 700 !important;
}

.dragable-content-amount {
  font-family: "Source Sans 3", sans-serif;
  margin-bottom: 0px;
  font-size: medium !important;
  font-weight: 350 !important;
}

.progress {
  height: 10px;
  border-radius: 2px;
}

.phone-icons {
  font-size: small;
}

.calender-table {
  font-size: small !important;
}

.newCircle {
  position: static;
  top: -5px;
  right: -5px;
  font-size: x-small;
  border-radius: 50%;
  animation: pulse 0.8s infinite alternate;
}

@keyframes pulse {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.2);
  }
}

.badge {
  font-weight: 500 !important;
  padding-top: 6px;
}

/* Calender CSS */
.calendar {
  font-size: small;
}

.calendar a {
  text-decoration: none !important;
  color: black !important;
  font-size: 14px !important;
}

.fc-daygrid-dot-event .fc-event-title {
  font-weight: 500 !important;
  color: rgb(94, 94, 94) !important;
}

.fc-direction-ltr .fc-daygrid-more-link {
  text-decoration: none !important;
  color: rgb(255, 0, 0) !important;
  font-size: 14px !important;
}

.fc .fc-button-primary {
  background-color: #ffffff;
  color: black;
  border: none;
}

.fc .fc-button-primary:disabled {
  background-color: #ffffff;
  color: #e3165b !important;
  font-weight: bold;
  text-transform: capitalize;
  border: none;
}

.fc .fc-button-primary:hover {
  background-color: #ffffff;
  color: #e3165b;
  border: none;
}

.fc .fc-button-primary:not(:disabled).fc-button-active,
.fc .fc-button-primary:not(:disabled):active {
  background-color: #ffffff;
  color: #e3165b;
  border: none;
}

.fc .fc-button-primary:disabled {
  background-color: #fafafa;
  color: black;
}

.fc .fc-button-primary:not(:disabled).fc-button-active:focus,
.fc .fc-button-primary:not(:disabled):active:focus {
  box-shadow: none !important;
  color: #e3165b !important;
}

.fc .fc-toolbar-title {
  font-size: 14px;
  font-weight: 600;
}

.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 0;
  padding-top: 1em;
  background-color: #ffffff;
}

.fc .fc-button-primary:focus {
  box-shadow: rgba(250, 250, 250, 0.5) 0px 0px 0px 0.2rem !important;
}

.calendar tbody {
  background-color: #ffffff;
}

.calendar thead {
  background-color: #ffffff;
}

.fc-direction-ltr .fc-timegrid-slot-label-frame {
  background-color: white;
}

.fc .fc-timegrid-axis-frame {
  background-color: white;
}

.fc .fc-timegrid-slot-label {
  background-color: white;
}

.modal-header {
  border-bottom: none;
}

.modal-footer {
  border-top: none;
}

.btn-close {
  --bs-btn-close-opacity: none;
}

.fc .fc-day-other {
  background-color: #fafafa;
  opacity: 1;
}

.fc .fc-daygrid-more-link:hover {
  background-color: #e3165a18 !important;
}

.fc-daygrid-dot-event.fc-event-mirror,
.fc-daygrid-dot-event:hover {
  background: #6f96f8;
}

/* Define styles for different appointmentRoleType */
/* .fc-direction-ltr .fc-list-table .fc-list-event-graphic {
  visibility: hidden;
} */
.fc-theme-standard .fc-popover {
  z-index: 1 !important;
}

.fc .fc-list-event-dot {
  border: calc(var(--fc-list-event-dot-width) / 2) solid #0077ff;
  border-radius: calc(var(--fc-list-event-dot-width) / 2);
  box-sizing: content-box;
  display: inline-block;
  height: 0px;
  width: 0px;
}

.fc-theme-standard td,
.fc-theme-standard th {
  overflow: hidden;
}

.fc .fc-event {
  background-color: transparent !important;
  border: none !important;
  color: #000 !important;
}

.fc-v-event {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 0 5px;
  border-radius: 5px;
}

.color-circle {
  padding: 0px 8px;
  border-radius: 3px;
  height: 18px;
}

.fc-license-message {
  display: none;
}

.no-arrow {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* additional styling to remove the default arrow */
  background: transparent;
  outline: none !important;
  border: none !important;
}

.usersCalendor li {
  cursor: pointer;
}

.allrolebox .box-1 {
  background-color: #40a578;
  width: 3px;
  height: 17px;
}

.allrolebox .box-2 {
  background-color: #9f7a56;
  width: 3px;
  height: 17px;
}

.allrolebox .box-3 {
  background-color: #c51605;
  width: 3px;
  height: 17px;
}

.allrolebox .box-4 {
  background-color: #e7b10a;
  width: 3px;
  height: 17px;
}

.allrolebox .box-5 {
  background-color: #9f0d7f;
  width: 3px;
  height: 17px;
}

.custom-modal .modal-content {
  box-shadow: 0px 5px 5px 500px #0000004d, 0px 20px 5px -15px #00000033,
    0px -10px 5px -10px #0000004d, 0px -10px 6px -15px #00000033;
}

/* .custom-modal .modal-backdrop.show {
  opacity: 0.8 !important;
  background-color: rgba(0, 0, 0, 0.8) !important;
} */

.mailtip {
  z-index: 1 !important;
}

/* Account  */

/* Activity  */

.accordion-button {
  border: none;
  background-color: transparent;
  /* Optional: To make the background transparent */
  outline: none;
  /* Remove focus outline */
  box-shadow: none;
  /* Remove box-shadow */
}

.accordion-button::after {
  display: none;
}

.scrollable-modal-body {
  max-height: 300px;
  /* Adjust as needed */
  overflow-y: auto;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #000;
  /* Adjust color as needed */
  margin: 0 auto;
  margin-bottom: 10px;
  /* Space between dot and line */
}

.dotted-line-container {
  display: flex;
  align-items: center;
  position: relative;
}

.dotted-line {
  flex-shrink: 0;
  width: 100%;
  /* Full width of the container */
  border-bottom: 1px dotted #000;
  /* Adjust the color as needed */
  margin-bottom: 16px;
  /* Adjust the spacing as needed */
}

.timeline {
  position: relative;
  margin-left: 20px;
  /* Adjust spacing as needed */
}

.timeline::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  /* Adjust position as needed */
  width: 2px;
  background: #000;
  /* Adjust color as needed */
}

.viewBtn {
  background-color: transparent;
  color: #2784db;
  border: none;
  font-weight: 500;
  /* font-weight: bolder; */
}

.viewBtn:hover {
  background-color: transparent;
  color: #2784db;
  border: none;
  font-weight: 500;
}

.clossBtn {
  background-color: transparent;
  color: #0e0f0f;
  border: none;
  font-size: 30px;
  font-weight: 500;
}

.clossBtn:hover {
  background-color: transparent;
  color: #0e0f0f;
  border: none;
  font-size: 30px;
  font-weight: 500;
}

.iconDesign {
  background-color: #9887ef;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f8f9fb;
  margin-right: 5px;
  transition: 0.2s;
}

.custom-accordion .accordion-item {
  border: none;
  border-bottom: 1px solid #aba4a4;
}

.addrow:hover {
  color: #006ff5 !important;
}

/* TrialNotification.css */
.notification-bar {
  display: flex;
  font-size: smaller;
  justify-content: space-between;
  align-items: center;
  /* background-color: #fff3cd; */
  background-color: #fff3cd;
  color: #4d3900;
  /* padding: 5px 10px; */
  border: 1px solid #ffeeba;
  /* border-radius: 5px; */
}

.notification-bar p {
  margin: 0;
  flex-grow: 1;
  font-size: 0.8em;
}

.close-button {
  background: none;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
  margin-left: 10px;
  margin-right: 5px;
}

.upgradebutton {
  background: none;
  border: 1px solid #af8101;
  color: #af8101;
  white-space: nowrap;
  font-size: small;
  cursor: pointer;
  margin-left: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.upgradebutton:hover {
  background-color: #d19900;
  color: #fff3cd;
}

/* dummy.css */
.custom-file-input {
  width: 20px !important;
  max-width: 350px !important;
  /* margin-left: 100px; */
  /* height: 30px; 
  padding: 5px; 
  font-size: 14px;  */
}

/* @media (max-width: 500px) {
  .attactUi{
    margin-left: 110px;
  }
} */

.attactUi {
  margin-left: 110px;
}

.preserve-whitespace {
  white-space: pre-wrap;
}

.qr-container {
  position: relative;
  display: inline-block;
  cursor: pointer;
  /* Change cursor to indicate clickable area */
  width: fit-content;
  /* Ensure container size fits QR code */
}

.qr-code {
  display: block;
}

.download-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.2s;
  pointer-events: none;
  /* Prevent download icon from blocking clicks */
}

.qr-container:hover .qr-code {
  opacity: 0.2;
}

.qr-container:hover .download-icon {
  opacity: 1;
}
.dropdown-menu li:hover {
  background-color: #f0f0f0; 
}
.dropdown-menu li button:hover,
.dropdown-menu li button:active {
  background-color: transparent; 
  color: inherit;
}


.success-note {
  font-size: 14px;
  color: #777;
}

.spinner-img {
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
